import React from "react";
import App from "next/app";
import { MitraProvider } from "../core/context/MitraContext";
import { SaHiringProvider } from "../core/context/SAHiringContext";
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn:
    "https://3b89c05ccb444d9bad6e3263d13c747d@o394302.ingest.sentry.io/5244236",
  environment: process.env.NODE_ENV
});

class _app extends App {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <React.Fragment>
        <SaHiringProvider>
          <MitraProvider>
            <Component {...pageProps} />{" "}
          </MitraProvider>
        </SaHiringProvider>
      </React.Fragment>
    );
  }
}

export default _app;
